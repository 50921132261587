<template>
  <div class="container">
    <div class="header">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item label="设备类型">
          <el-input clearable size="small" v-model="searchForm.deviceCodeName" placeholder="请输入设备类型" style="width:150px"></el-input>
        </el-form-item>
        <el-form-item label="设备型号">
          <el-input clearable size="small" v-model="searchForm.deviceType" placeholder="请输入设备型号" style="width:150px"></el-input>
        </el-form-item>
        <el-form-item label="厂家名称">
          <el-input clearable size="small" v-model="searchForm.manufactorName" placeholder="请输入厂家名称" style="width:150px"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="initTable">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="success" size="small" icon="el-icon-plus" @click="open('add')">新增设备型号</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content">
      <el-table :data="deviceModelList" stripe style="width: 100%" height="100%" v-loading="loading">
        <el-table-column prop="deviceType" label="设备型号" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="manufactorName" label="厂家名称" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="deviceCodeName" label="设备类型" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="修改" placement="top">
              <el-button size="mini" @click="open('edit',scope.row)" type="warning" icon="el-icon-edit" circle v-role="'deviceModel:edit'"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="删除" placement="top">
              <el-button type="danger" @click="deleteModel(scope.row.id)" size="mini" icon="el-icon-delete-solid" circle v-role="'deviceModel:del'"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="10" layout="total, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
    <!-- 设备型号弹窗 -->
    <el-dialog :title="optFlag?'添加设备型号':'修改设备型号'" :visible.sync="deviceModelShow" width="30%" @close="clear" top="5%" v-dialogDrag :close-on-click-modal="false">
      <el-form :model="deviceModelForm" ref="deviceModelForm" :rules="rules" label-width="100px">
        <el-form-item label="设备类型" prop="deviceCodeName">
          <el-input clearable v-model="deviceModelForm.deviceCodeName" placeholder="请输入设备类型"></el-input>
        </el-form-item>
        <el-form-item label="设备型号" prop="deviceType">
          <el-input clearable v-model="deviceModelForm.deviceType" placeholder="请输入设备型号"></el-input>
        </el-form-item>
        <el-form-item label="厂家编码" prop="manufactorCode">
          <el-input clearable v-model="deviceModelForm.manufactorCode" placeholder="请输入厂家编码"></el-input>
        </el-form-item>
        <el-form-item label="厂家名称" prop="manufactorName">
          <el-input clearable v-model="deviceModelForm.manufactorName" placeholder="请输入厂家名称"></el-input>
        </el-form-item>
        <el-form-item label="智赣主键" prop="outerSystemId">
          <el-input clearable v-model="deviceModelForm.outerSystemId" placeholder="请输入智赣主键"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="deviceModelShow = false">取 消</el-button>
        <el-button type="primary" @click="addModel" v-if="optFlag">添 加</el-button>
        <el-button type="primary" @click="updateModel" v-if="!optFlag">修 改</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  data () {
    return {
      currentPage: 1,
      total: 0,
      deviceModelList: [],
      deviceModelId: 0, // 设备型号ID
      deviceModelForm: {}, // 建筑表单
      searchForm: {},
      deviceModelShow: false,
      loading: false,
      optFlag: true,
      rules: {
        deviceType: [
          { required: true, message: '请选择设备型号', trigger: 'blur' }
        ],
        manufactorName: [
          { required: true, message: '请选择厂家名称', trigger: 'blur' }
        ],
        deviceCodeName: [
          { required: true, message: '请选择设备类型', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.initTable()
  },
  methods: {
    async handleCurrentChange (val) {
      this.currentPage = val
      this.initTable()
    },
    // 初始化设备型号表格
    async initTable () {
      const data = {}
      if (this.searchForm.deviceCodeName) {
        data.deviceCodeName = this.searchForm.deviceCodeName
      }
      if (this.searchForm.deviceType) {
        data.deviceType = this.searchForm.deviceType
      }
      if (this.searchForm.manufactorName) {
        data.manufactorName = this.searchForm.manufactorName
      }
      data.pageNo = this.currentPage
      data.pageSize = 10

      this.loading = true

      const { data: result } = await this.$axios.get('/equipmentModel/list', { params: data })
      if (result.code === 200) {
        this.loading = false
        this.deviceModelList = result.data.result
        this.total = result.data.total
      } else {
        this.loading = false
        this.$message.error(result.msg)
      }
    },
    // 删除某条数据
    async deleteModel (id) {
      const confirm = await this.$confirm('此操作将永久删除该设备型号, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirm === 'confirm') {
        const { data: result } = await this.$axios.delete(`/equipmentModel/del/${id}`)
        if (result.code === 200) {
          this.$message.success('删除成功')
          const totalPage = Math.ceil((this.total - 1) / 10) // 总页数
          this.currentPage = this.currentPage > totalPage ? totalPage : this.currentPage
          this.currentPage = this.currentPage < 1 ? 1 : this.currentPage
          this.initTable()
        } else {
          this.$message.error(result.msg)
        }
      }
    },
    // 修改某条数据
    updateModel: _.debounce(function () {
      this.$refs.deviceModelForm.validate(async valid => {
        if (valid) {
          const { data: result } = await this.$axios.put(`/equipmentModel/edit/${this.deviceModelId}`, this.deviceModelForm)
          if (result.code === 200) {
            this.$message.success('修改成功')
            this.deviceModelShow = false
            this.initTable()
          } else {
            this.$message.error(result.msg)
          }
        }
      })
    }, 1000),
    // 新增建筑
    addModel: _.debounce(function () {
      this.$refs.deviceModelForm.validate(async valid => {
        if (valid) {
          const { data: result } = await this.$axios.post('/equipmentModel/add', this.deviceModelForm)
          if (result.code === 200) {
            this.$message.success('新增成功')
            const totalPage = Math.ceil((this.total + 1) / 10) // 总页数
            this.currentPage = this.currentPage > totalPage ? totalPage : this.currentPage
            this.currentPage = this.currentPage < 1 ? 1 : this.currentPage
            this.deviceModelShow = false
            this.initTable()
          } else {
            this.$message.error(result.msg)
          }
        }
      })
    }, 1000),
    open (flag, row) {
      switch (flag) {
        case 'add':
          this.deviceModelShow = true
          this.$nextTick(() => {
            this.$refs.deviceModelForm.clearValidate()
          })
          this.optFlag = true
          break
        case 'edit':
          this.deviceModelShow = true
          this.optFlag = false
          this.$nextTick(() => {
            this.$refs.deviceModelForm.clearValidate()
          })
          this.deviceModelForm = JSON.parse(JSON.stringify(row))
          this.deviceModelId = row.id
          break
      }
    },
    clear () {
      this.deviceModelForm = {}
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
